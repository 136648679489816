.buttonBackWrapper {
  .backButton {
    display: inline-flex;
    align-items: center;
    grid-gap: 8px;
    padding: 12px;
    cursor: pointer;
  }

  .noBorder {
    border-bottom: 0 !important;

    .backButton {
      padding: 0 !important;
    }
  }
}
