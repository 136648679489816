@import "../../../../assets/scss/base/variables";

.addTagWrapper {
  height: 100%;
  display: grid;
  grid-template-rows: max-content 1fr max-content;

  /* Input */
  .personalized {
    flex: 1;
    .personalizedInput {
      height: 40px;

      color: $text-disabled;
      padding: 12px;

      display: flex;
      align-items: center;
      justify-content: space-between;

      border: $border;
      border-radius: $border-radius-4;

      cursor: pointer;

      svg {
        path {
          fill: $action-active;
        }
      }
    }
  }
}
.semscInput {
  box-sizing: border-box;
  height: 48px;
  border: $border;
  border-radius: $border-radius-4;
  margin: 0;
  padding: 12px;
  :focus {
    box-shadow: none;
  }
  &::placeholder {
    color: $text-disabled;
    font-size: 16px;
  }
}
.tagPopoverWrp {
  overflow: hidden;
}

// Custom PopUp - not working inside wrapper
.popDlvnWrapper {
  padding: 10px;
}
.popDlvnItem {
  padding: 10px;
  cursor: pointer;
  border-radius: $border-radius-4;
}

.popDlvnItem:hover {
  background-color: $alternate-light;
}
