.wrapper {
  background-image: linear-gradient(to right, rgb(209 204 204), rgb(221, 221, 221));
  animation: shine 1s infinite;
  background-size: 40px 100%;
  background-repeat: no-repeat;
  background-position: left 0px top 0;
}
@keyframes shine {
  to {
    background-position: right -40px top 0;
  }
}
