// need to fix later
$fbp-primary-contrast: #ffffff;
$fbp-primary-states-outlined-resting-border: rgba(98, 88, 255, 0.5);
$fbp-primary-states-outlined-hover-background: rgba(98, 88, 255, 0.08);
$fbp-menu-hover: rgba(100, 56, 188, 0.04);
$fbp-secondary-contrast: #ffffff;
$fbp-action-hover: rgba(98, 88, 255, 0.06);

// End - need to fix later

/* Primary Colors */
$white: #ffffff;
$white-accent: #f5f5dc;
$violet: #6258ff;
$gray-50: #fafafa;

$primary-light: rgba(98, 88, 255, 0.08);

$secondary-main: #006df5;
$secondary-light: rgba(217, 226, 255, 1);
$secondary-states: rgba(0, 109, 245, 0.08);
$secondary-states-contained-hover: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), #006df5;
$secondary-dark: #002e75;

$secondary-states-contained-background: rgba(0, 109, 245, 1);
$secondary-states-contained-resting-border: rgba(0, 109, 245, 0.5);

/* Common Color */

/* Success Colors */
$success-main: #008945;
$success-dark: #006625;
$success-light: #baf0cb;
$success-contrast: #ffffff;
$success-states-outlined-resting-border: rgba(0, 137, 69, 0.5);
$success-states-outlined-hover-background: rgba(0, 137, 69, 0.1);
$success-states-contained-hover-background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), #008945;
$success-alert-content: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), #008945;
$success-alert-background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #008945;

/* error colors  */
$error-main: #ed0039;
$error-dark: hsl(351, 100%, 29%);
$error-light: #ffdad9;
$error-contrast: #ed0039;
$error-states-outlined-resting-border: rgba(237, 0, 57, 0.5);
$error-states-outlined-hover-background: rgba(237, 0, 57, 0.08);
$error-states-contained-hover-background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), #ed0039;
$error-alert-content: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), #ff264a;
$error-alert-background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #ff264a;

/* Warning Colors */
$warning-main: #f2994a;
$warning-dark: #944200;
$warning-light: #ffdcc3;
$warning-contrast: #ffffff;
$warning-states-outlined-resting-border: rgba(242, 153, 74, 0.5);
$warning-states-outlined-hover-background: rgba(242, 153, 74, 0.08);
$warning-states-contained-hover-background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), #f2994a;
$warning-alert-content: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), #f2994a;
$warning-alert-background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #f2994a;

/* Info Colors */
$info-main: #a352c2;
$info-dark: #5f0085;
$info-light: #f9d8ff;
$info-contrast: #ffffff;
$info-states-outlined-resting-border: rgba(163, 82, 194, 0.5);
$info-states-outlined-hover-background: rgba(163, 82, 194, 0.08);
$info-states-contained-hover-background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), #a352c2;
$info-alert-content: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), #a352c2;
$info-alert-background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #a352c2;

/* End - Common Color */

/* Text Colors - primary, secondary, action/active - text, background */
$text-primary: #1c1b1f;
$text-secondary: #546376;
$action-active: #949db2;
$text-disabled: rgba(84, 99, 118, 0.66);
$action-selection: rgba(28, 27, 31, 0.08);
$action-hover: rgba(98, 88, 255, 0.06);
$other-divider: rgba(148, 157, 178, 0.12);
$outlined-border: rgba(148, 157, 178, 0.24);

$secondary-states-outlined-hover-background: rgba(0, 109, 245, 0.08);

$other-dark: #133159;

$skeleton-bg: rgba(147, 147, 147, 0.08);

$background-paper: #ffffff;

$alternate-light: #d9e2ff;

/* Modal */
$background-modal: #f2f3f6;

/* Font Size */
$fs-13: 13px;
$fs-14: 14px;
$fs-15: 15px;
$fs-16: 16px;
$fs-18: 18px;
$fs-20: 20px;
$fs-24: 24px;
$fs-28: 28px;
$fs-48: 48px;

/* Font width */
$fw-300: 300;
$fw-400: 400;
$fw-500: 500;
$fw-600: 600;

/* Line hight */
$lh-400: 400;
$lh-18: 18px;
$lh-20: 20px;
$lh-22: 22px;
$lh-26: 26px;
$lh-24: 24px;
$lh-28: 28px;
$lh-30: 30px;

/* letter spacing  */
$ls-46: 0.46px;
$ls-15: 0.15px;
$ls-17: 0.17px;
$ls-16: 0.16px;
$ls-4: 0.04px;

/* Border */
$border: 1px solid rgba(148, 157, 178, 0.24);
$border-2px: 2px solid rgba(148, 157, 178, 0.24);
$border-2: 1px solid rgba(97, 97, 97, 0.315);
$border-3: 1.5px solid rgba(0, 109, 245, 0.5);
$border-4: 1.5px solid #949db2;
$border-5: 1px solid rgba(148, 157, 178, 0.12);
$outline-border: 1px solid rgba(98, 88, 255, 0.5);

$border-radius-4: 4px;
$border-radius-8: 8px;
$border-radius-10: 10px;
$border-radius-16: 16px;

/* Shadow */
$list-item-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.05);
$box-shadow: 0px 3px 5px -1px rgba(148, 157, 178, 0.2), 0px 5px 8px rgba(148, 157, 178, 0.14),
  0px 1px 14px rgba(148, 157, 178, 0.12);

/* Transition */
$transition: all 300ms ease-in-out;

$secondary-states-outlined: #006df514;

// Keypad
$kp-top-icon: #d3d3d3;
$kp-single: #e5e8ef;

// Break Point - Dashboard

$breakpoint-3xl: 1920px;
$breakpoint-xxl: 1440px;
$breakpoint-xl: 1366px;
$breakpoint-lgx: 1140px;
$breakpoint-lg: 1100px;
$breakpoint-md: 768px;

$breakpoint-ol: 1250px;
$breakpoint-lp: 1024px;
$breakpoint-od: 780px;
