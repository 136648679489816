@import "../../../assets/scss/base/variables";

.centerWrapper {
  // height: calc(100vh - 196px);
  height: calc(100vh - 190px);
  display: flex;

  @media screen and (min-width: 1440px) {
    height: calc(100vh - 198px);
  }

  @media screen and (max-width: 1140px) {
    overflow-y: auto;
  }

  .tabScreenOne {
    @media screen and (max-width: 1140px) {
      width: 100%;

      .rightSide {
        width: 100%;
      }

      .profile {
        display: flex;
        align-items: center;
        grid-gap: 20px;
        // border-bottom: 1px solid $outlined-border;
        padding: 24px;
        @media screen and (max-width: 1140px) {
          justify-content: center;
          flex-direction: column;
          text-align: center;
          grid-gap: 12px;
        }

        .avatar {
          height: 56px;
          width: 56px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          text-transform: uppercase;
          background: $primary-light;
          color: $secondary-main;
          flex-shrink: 0;
        }
      }
      .overflowHidden {
        overflow: hidden !important;
      }

      .proStatus {
        margin: 2px 0 0 0;

        span {
          color: $success-main;
          margin-left: 8px;
        }
      }
    }
  }
}
