@import "../../../assets/scss/base/variables";

.headerWrapper {
  height: 82px;
  padding: 12px;
  border-bottom: 1px solid $outlined-border;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .headerLeft {
    display: flex;
    align-items: center;
    grid-gap: 20px;

    .headerLeftIcon {
      height: 58px;
      width: 58px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $secondary-states;
      border-radius: 4px;
      flex-shrink: 0;
    }
  }

  .headerRight {
    display: flex;
    align-items: center;
    grid-gap: 20px;

    .headerRightIcon {
      background: $other-divider;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      flex-shrink: 0;
    }

    .headerRightSubTitle {
      color: $secondary-main !important;
      margin: 2px 0 0 0;
    }
  }
}

.popDlvnWrapper {
  padding: 10px;
}
.popDlvnItem {
  padding: 10px;
  cursor: pointer;
  border-radius: $border-radius-4;
}
.popDlvnItem:hover {
  background-color: $alternate-light;
}
.personalizedInput {
  height: 40px;
  color: $text-disabled;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: $border;
  border-radius: $border-radius-4;
  cursor: pointer;

  svg {
    path {
      fill: $action-active;
    }
  }
}

.alertText {
  color: $error-main;
  font-size: 20px;
  transition: all 0.3s ease-in-out;
  opacity: 1;
  animation-name: blink;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
@keyframes blink {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.2;
  }
}
