@import "../../../../assets/scss/base/variables";

.addCampaignWrapper {
  height: 100%;

  display: grid;
  grid-template-rows: max-content 1fr max-content;

  /* Input */
  .personalized {
    flex: 1;
    .personalizedInput {
      height: 40px;

      color: $text-disabled;
      padding: 12px;

      display: flex;
      align-items: center;
      justify-content: space-between;

      border: $border;
      border-radius: $border-radius-4;

      cursor: pointer;

      svg {
        path {
          fill: $action-active;
        }
      }
    }
  }
}

.popDlvnWrapper {
  padding: 10px;
}
.popDlvnItem {
  padding: 10px;
  cursor: pointer;
  border-radius: $border-radius-4;
}

.popDlvnItem:hover {
  background-color: $alternate-light;
}
