@import "../../assets/scss/base/variables";

.wrapper {
  height: calc(100vh - 150px) !important;
  padding-bottom: 75px !important;
  &::-webkit-scrollbar {
    width: 4px !important;
  }

  @media screen and (min-width: $breakpoint-xxl) {
    height: calc(100vh - 280px) !important;
  }

  .bodyWrapper {
    background-color: $white;
    // max-height: calc(100vh - 50px);
    display: grid;
    place-content: center;

    @media screen and (max-width: $breakpoint-md) {
      place-content: unset;
    }

    &.bodyWrapper987 {
      width: 987px;

      @media screen and (max-width: $breakpoint-ol) {
        // height: calc(100vh - 5%);
        max-width: 720px;
        margin: auto;
        overflow-y: auto;
      }
    }

    .modalContentWrapper {
      display: flex;
      gap: 68px;

      padding: 24px;
      overflow-y: auto;

      @media screen and (max-width: $breakpoint-md) {
        gap: 30px;
        flex-direction: column;
      }
    }

    .modalHeaderTitle {
      font-weight: $fw-500;
      font-size: $fs-16;
      line-height: $lh-28;
      text-transform: capitalize;
    }

    .leftWpr {
      width: 324px;
      height: 530px;
      padding: 24px 26px;

      background-color: $gray-50;
      border: $border;
      border-radius: $border-radius-8;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media screen and (max-width: $breakpoint-ol) {
        width: auto;
      }

      @media screen and (max-width: $breakpoint-md) {
        height: auto;
      }

      .leftHeader {
        color: $action-active;
        display: flex;
        align-items: center;
        gap: 6px;
        margin-bottom: 32px;

        svg {
          path {
            fill: $action-active;
          }
        }
      }

      .leftEdit {
        color: $secondary-main;
        background-color: transparent;

        border: 1px solid $secondary-main;
        border-radius: $border-radius-4;

        display: flex;
        justify-content: center;
        align-items: center;
        padding: 6px 12px;
        gap: 6px;

        font-weight: $fw-500;
        font-size: $fs-14;
        line-height: $lh-24;

        cursor: pointer;

        svg {
          path {
            fill: $secondary-main;
          }
        }
      }
      .leftList {
        display: flex;
        flex-direction: column;
        gap: 24px;

        @media screen and (max-width: $breakpoint-ol) {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }

    .rightWrp {
      .sobrSettingList:not(:last-child) {
        margin-bottom: 50px;
      }

      .sobrSlmTitle {
        display: block;
        margin-top: 20px;
        margin-bottom: 15px;
      }

      .sobrSlmRadio {
        padding-inline-start: 16px;
        margin-bottom: 16px;
      }

      .sobrSlAddon {
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: 18px 20px;

        border: $border;
        border-radius: $border-radius-4;
      }

      .sobrSlaLeft {
        display: flex;
        align-items: center;
        gap: 16px;
      }

      .sobrSlalIcon {
        width: 42px;
        height: 42px;
        padding: 10px;

        display: grid;
        place-content: center;

        background: $other-divider;
        border-radius: $border-radius-4;
      }

      .sobChange {
        color: $text-secondary;
        background-color: transparent;
        cursor: pointer;

        display: grid;
        place-content: center;

        font-weight: $fw-500;
        font-size: $fs-14;
        line-height: $lh-20;

        width: 83px;
        height: 36px;
        padding: 6px 12px;

        border: 1.5px solid $action-active;
        border-radius: $border-radius-4;
      }

      .sobInput {
        color: $text-secondary;
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          &:first-child {
            display: flex;
            align-items: center;

            padding: 0px 4px 0px 12px;
            gap: 24px;

            width: 97px;
            height: 40px;

            border: $border;
            border-radius: 4px 0px 0px 4px;
          }
          &:last-child {
            display: grid;
            place-content: center;
            width: 43px;
            height: 40px;
            background: $action-selection;
            border-radius: 0px 4px 4px 0px;
          }
        }
      }
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 16px;
    border-top: 1px solid $outlined-border;

    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: $white;

    .footerCancel {
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 0.46px;
      line-height: 26px;
      color: $text-secondary;
      cursor: pointer;
    }
  }
  .forwardWrp {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;
    height: 92px;
    .forwardLabe {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.15px;
      color: #1c1b1f;
    }
    .forwardNumber {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 2px;
      height: 48px;
      padding: 0px 12px;
      border: 1px solid rgba(148, 157, 178, 0.24);
      border-radius: 4px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      letter-spacing: 0.15px;
      color: rgba(84, 99, 118, 0.66);
      width: 100%;
      align-items: left;
      justify-content: center;
    }
  }
}
.center {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*----------  End Tab  ----------*/
.personalized {
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  .personalizedInput {
    height: 40px;
    color: $text-disabled;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: $border;
    border-radius: $border-radius-4;
    cursor: pointer;
    svg {
      path {
        fill: $action-active;
      }
    }
  }
}
.mainWrp {
  flex: 1;
}
.popDlvnWrapper {
  padding: 10px;
}
.popDlvnItem {
  padding: 10px;
  cursor: pointer;
  border-radius: $border-radius-4;
}
.popDlvnItem:hover {
  background-color: $alternate-light;
}
.scriptPreview {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 4px;
  svg {
    display: flex;
  }
}

.settingOverviewPopupInner {
  overflow: hidden !important;
  background-color: $gray-50 !important;
  padding: 20px;
  box-sizing: border-box;
}
.scriptContent {
  background-color: $white;
  padding: 40px;
  box-sizing: border-box;
  overflow: hidden;
  overflow-y: auto;
  border: $border;
  box-shadow: $box-shadow;
  border-radius: 0px 20px 0px 0px;
  height: 100% !important;
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
}
