@import "../../../assets/scss/base/variables";

/* footer css Start */
.footerWrapper {
  background-color: $white;

  height: 82px;
  padding: 20px 24px;
  border-top: 1px solid $outlined-border;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: $breakpoint-lgx) {
    .footerButtonText {
      display: none;
    }
  }

  .footerLeft {
    display: flex;
    align-items: center;

    @media screen and (max-width: $breakpoint-lgx) {
      flex-direction: row-reverse;
      grid-gap: 12px;
    }

    .footerLeftRecord {
      background: $error-light;
      color: $error-main !important;
      padding: 4px 10px 4px 5px;
      border-radius: 16px;
      display: flex;
      align-items: center;
      grid-gap: 2px;
      cursor: pointer;

      @media screen and (max-width: $breakpoint-lgx) {
        padding: 4px;
      }
    }

    .footerLeftSingle {
      display: flex;
      align-items: center;
      grid-gap: 10px;
    }

    .footerLeftDuration {
      @media screen and (max-width: $breakpoint-lgx) {
        flex-direction: column;
        align-items: flex-start;
        grid-gap: 2px;
      }
    }

    .footerLeftRecordText {
      @media screen and (max-width: $breakpoint-lgx) {
        display: none !important ;
      }
    }
  }

  .footerMiddle {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 8px;

    @media screen and (max-width: $breakpoint-lgx) {
      margin-left: auto;
      margin-right: 8px;
    }

    button {
      @media screen and (min-width: $breakpoint-lgx) {
        width: 126px;
      }
    }
  }

  .footerRight {
    display: flex;
    grid-gap: 12px;
    align-items: center;

    button {
      @media screen and (min-width: $breakpoint-lgx) {
        // width: 126px;
        width: auto;
      }
    }
  }
}

.drop {
  & > div:nth-child(3) {
    box-shadow: -2px 9px 98px -8px #bbb !important;
    padding: 24px;
    position: relative;
    overflow: unset !important;
    top: calc(100% - 472px) !important;
    border-radius: 8px !important;

    &:after {
      content: "";
      bottom: -32px;
      left: 50%;
      width: 0;
      height: 0;
      border-left: 32px solid transparent;
      border-right: 32px solid transparent;
      border-top: 32px solid $white;
      position: absolute;
      z-index: 1;
      transform: translateX(-50%);
    }
  }
}

.innerWrap {
  max-width: 346px !important;
  max-height: 325px !important;
}
