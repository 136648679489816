.wrapper {
  display: flex;
  flex-wrap: wrap;

  .eachIcon {
    display: flex;
    padding: 10px 20px;
    border: 1px solid #ddd;
    margin: 5px;
    gap: 10px;
    flex: 1;
    align-items: center;

    svg {
      display: flex;
      align-items: center;
    }
  }
}
