@import "../../assets/scss/base/variables";

.leftSidebar {
  .leftSidebarTitle {
    transition-delay: 0.2s;
  }
  .ToggleSidebar {
    display: grid;
    place-items: center;
    cursor: pointer;
    height: 40px;
  }
  .CreatNewFolder {
    opacity: 1;
    transition-delay: 0.3s;
    .CreatNewFolderLi {
      display: flex;
      align-items: center;
      border-radius: 4px;
      padding-left: 10px;
      &:hover {
        background: $fbp-action-hover;
      }
    }
  }
  &.hideSidebar {
    .leftSidebarTitle {
      width: 0;
      opacity: 0;
      pointer-events: none;
      transition-delay: 0s;
    }
    .CreatNewFolder {
      opacity: 0;
    }
  }
}

.leftSide {
  position: relative;
  max-width: 268px;
  transition: all 0.4s ease-in-out;
  border-right: 1px solid rgba(128, 128, 128, 0.14);
  .formSidebarList {
    border-radius: 4px;
    .moreIcon {
      width: 28px;
      height: 24px;
      margin-left: auto;
      opacity: 0;
      transition: opacity 0.3s;
    }
    &:hover {
      .moreIcon {
        opacity: 1;
      }
    }
  }
}
.fbpHideSidebar {
  .folderListsBottom {
    opacity: 0;
    pointer-events: none;
    width: 0;
  }
  .formSidebarList {
    visibility: hidden;
    width: 0;
    pointer-events: none;
    transition: all 0.3s ease-in-out;
    opacity: 0;
  }
}

.fbpMoreOptions {
  width: 28px;
  height: 24px;
  margin-left: auto;
  opacity: 0;
  transition: opacity 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  li span {
    display: inline-flex;
  }
}

.fbpLastCreateFolder {
  max-height: 200px;
  padding-bottom: 0;
  li {
    &:hover {
      .fbpMoreOptions {
        opacity: 1;
      }
    }
  }
}

@media (max-width: 1200px) {
  .leftSidebar {
    width: 268px;
    display: block;
    position: fixed;
    top: 80px;
    z-index: 99;
    background: #fff;
    left: -100%;
    height: calc(100% - 80px);
    transition: all 0.5s ease;
  }
  .showTabSidebar {
    .leftSidebar {
      left: 0;
      border-radius: 0;
      box-shadow: var(--fbp-elevation-6);
    }
  }
}
