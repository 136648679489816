@import "../../../assets/scss/base/variables";

.wrapper {
  .title {
    @media screen and (max-width: $breakpoint-lgx) {
      display: none;
    }
  }
  .backButtonSection {
    display: none;
    @media screen and (max-width: $breakpoint-lgx) {
      display: block !important;
      padding: 8px 12px;
      border-bottom: $border;
      margin-bottom: 20px;
      color: $text-primary;

      svg {
        path {
          fill: $action-active;
        }
      }
    }
  }

  .profile {
    display: flex;
    align-items: center;
    gap: 20px 16px;
    border-bottom: 1px solid $outlined-border;
    padding: 24px;
    @media screen and (max-width: $breakpoint-lgx) {
      justify-content: center;
      flex-direction: column;
      text-align: center;
      grid-gap: 12px;
      border-bottom: none;
    }

    .avatar {
      height: 56px;
      width: 56px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      background: $primary-light;
      flex-shrink: 0;
    }
  }

  .proStatus {
    margin: 2px 0 0 0;

    span {
      color: $success-main;
      margin-left: 8px;
    }
  }

  .truncate {
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    white-space: nowrap !important;

    @media screen and (max-width: $breakpoint-xxl) {
      display: inline-block;
      width: 195px;
    }
  }

  .overflowHidden {
    overflow: hidden !important;
  }
  // Wrapper end
}
