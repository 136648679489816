@import "../../../assets/scss/base/variables";

.wrapper {
  height: calc(100vh - 211px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .countWrapper {
    height: 180px;
    width: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 24px 0 78px 0;
  }

  .count {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $secondary-states;
    border: 1px solid $secondary-states-contained-resting-border;
    border-radius: 50%;
    height: 150px;
    width: 150px;

    outline: 1px solid $secondary-states;
    outline-offset: 15px;

    -webkit-animation: callWaiting 4s infinite; /* Chrome, Safari, Opera */
    animation: callWaiting 2s infinite ease-in-out;

    /* Chrome, Safari, Opera */
    @-webkit-keyframes callWaiting {
      50% {
        outline-offset: 0;
      }
    }
    /* Standard syntax */
    @keyframes callWaiting {
      50% {
        outline-offset: 0;
      }
    }
  }
}
