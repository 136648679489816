@import "../../../../assets/scss/base/variables";

.sendEmailWrapper {
  height: 100%;
  display: grid;
  grid-template-rows: max-content 1fr max-content;

  .sendModalBody {
    padding-top: 32px;
    padding-inline: 24px;
    border-top: 1px solid $other-divider;

    /* Subject */
    .semSubject {
      margin-bottom: 24px;
    }

    /* Message */
    .semMessage {
      margin-bottom: 24px;
    }
    .semsTitle {
      display: block;
      color: $text-primary;
      margin-bottom: 4px;
    }

    .semsContent {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    .semscInput {
      box-sizing: border-box;

      height: 48px;

      border: $border;
      border-radius: $border-radius-4;

      margin: 0;
      padding: 12px;

      :focus {
        box-shadow: none;
      }

      &::placeholder {
        color: $text-disabled;
        font-size: 16px;
      }
    }

    .semMessage {
      .ssmmTextarea {
        border: $border;
        border-radius: $border-radius-4;
        width: 100%;
        height: 120px;
        padding: 12px;
        resize: none;

        &:focus-visible {
          outline: none;
        }
      }
    }

    /* Attachment */
    .semAttachment {
      display: flex;
      flex-direction: column;
      gap: 24px;

      &:has(.ssmalAttachment) {
        .ssmaLeft {
          width: 100%;
        }
      }

      /* Attachment Left */
      .attachFile {
        color: $secondary-main;
        background-color: $secondary-states-outlined;
        max-width: 160px;
        max-height: 36px;
        padding: 6px 12px;

        margin-bottom: 12px;
      }

      .ssmalAttachText {
        color: $action-active;
      }

      .ssmalAttachment {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
        margin-top: 20px;
      }

      .ssmalaItem {
        background-color: $other-divider;

        padding: 10px;

        border-radius: $border-radius-4;

        display: flex;
        align-items: center;
        gap: 25px;
      }

      .ssmalaItemContent {
        display: flex;
        gap: 12px;
      }

      .ssmalaText {
        .ssmalatTitle {
          color: $text-secondary;

          overflow: hidden;
          max-width: 138px;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        span:last-child {
          display: block;

          color: $action-active;
        }
      }

      /* Attachment right */
      .ssmaRight {
        display: flex;
        align-items: center;
        gap: 12px;

        /* Bookmark */
        .ssmarBookmark {
          display: grid;
          place-content: center;
          width: 48px;
          height: 48px;
          border: $border;
          border-radius: $border-radius-4;
          cursor: pointer;
        }
      }
    }

    /* Input */

    .semPersonalized {
      flex: 1;

      .cdlVnInput {
        height: 48px;

        color: $text-disabled;
        padding: 12px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        border: $border;
        border-radius: $border-radius-4;

        cursor: pointer;

        svg {
          path {
            fill: $action-active;
          }
        }
      }
    }

    /* Attachment right end */
  }
}

// Custom PopUp - not working inside wrapper
.popDlvnWrapper {
  padding: 10px;
}
.popDlvnItem {
  padding: 10px;
  cursor: pointer;
  border-radius: $border-radius-4;
}

.popDlvnItem:hover {
  background-color: $alternate-light;
}
