@import "../../../assets/scss/base/variables";

.dialerBasicInfoWrapper {
  width: 360px;
  border-right: 1px solid $outlined-border;
  padding: 20px 24px 0 24px;

  @media screen and (max-width: $breakpoint-xxl) {
    width: 300px;
  }
  @media screen and (max-width: $breakpoint-md) {
    display: none;
  }

  .innerScroll {
    height: calc(100% - 55px);
    padding: 0;
    .error {
      min-height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .innerWrapper {
    border: 1px solid $outlined-border;
    border-radius: 8px;
    padding: 0 0 16px 0;
    margin-right: 5px;
    .singleInfo {
      display: flex;
      align-items: center;
      grid-gap: 20px;
      padding: 24px 20px;
      border-bottom: 1px solid $outlined-border;

      &:last-child {
        border-bottom: 0 !important;
      }
    }
  }
}
