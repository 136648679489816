@use "sass:math";
@import "../../../assets/scss/base/variables";

.dialingLinesWrapper {
  background: $other-divider;
  padding: 20px;
  flex: 1;

  .innerWrp {
    padding: 12px 0 0 0;
    height: calc(100% - 12px) !important;
  }

  .singleCard {
    background: $white;
    border: 1px solid $outlined-border;
    text-align: center;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 316px;
    padding: 12px;
    border-radius: 8px;
    opacity: 1;

    @media screen and (max-width: $breakpoint-xxl) {
      min-height: 250px;
    }
  }

  .singleCardContent {
    margin-top: -24px;
  }

  .singleCardAvatar {
    height: 64px;
    width: 64px;
    border-radius: 50%;
    background: $primary-light;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  .singleCardFooter {
    display: flex;

    .cardFooterConnect {
      position: absolute;
      bottom: 12px;
      left: 12px;
    }

    .cardFooterDot {
      position: absolute;
      bottom: 3px;
      right: 12px;
      cursor: pointer;
      padding-bottom: 10px;
      padding-right: 10px;
    }
  }

  .dialerCardOpacityThree {
    cursor: not-allowed;

    @for $i from 1 through 10 {
      &:nth-child(#{$i}) {
        transition: opacity #{math.div($i * 40, 100)}s;
        opacity: 0.3 !important;
      }
    }

    .singleCardFooter {
      cursor: not-allowed;
    }
  }

  .GridTen {
    &.cards {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-gap: 12px;
      height: 100%;

      @media screen and (max-width: $breakpoint-ol) {
        grid-template-columns: repeat(4, 1fr);
      }

      @media screen and (max-width: $breakpoint-lp) {
        grid-template-columns: repeat(2, 1fr);
      }

      .singleCard {
        min-height: 250px;
      }
    }
  }

  .gridNine {
    &.cards {
      display: grid;
      grid-gap: 12px;
      grid-template-columns: repeat(5, 1fr);
      height: 100%;

      @media screen and (max-width: $breakpoint-md) {
        grid-template-columns: repeat(2, 1fr);
      }

      .singleCard:last-child {
        grid-row-start: 1;
        grid-column-start: 5;
        grid-row-end: 3;
        grid-column-end: 5;

        @media screen and (max-width: $breakpoint-md) {
          grid-row-start: unset !important;
          grid-column-start: unset !important;
          grid-row-end: unset !important;
          grid-column-end: unset !important;
        }
      }
    }
  }

  .gridSeven {
    &.cards {
      display: grid;
      grid-gap: 12px;
      grid-template-columns: repeat(4, 1fr);
      height: 100%;

      @media screen and (max-width: $breakpoint-md) {
        grid-template-columns: repeat(2, 1fr);
      }

      .singleCard:last-child {
        grid-row-start: 1;
        grid-column-start: 4;
        grid-row-end: 3;
        grid-column-end: 4;

        @media screen and (max-width: $breakpoint-md) {
          grid-row-start: unset !important;
          grid-column-start: unset !important;
          grid-row-end: unset !important;
          grid-column-end: unset !important;
        }
      }
    }
  }

  .gridFive {
    &.cards {
      display: grid;
      grid-gap: 12px;
      height: 100%;

      .singleCard:last-child {
        grid-row-start: 1;
        grid-column-start: 3;
        grid-row-end: 3;
        grid-column-end: 3;

        @media screen and (max-width: $breakpoint-ol) {
          grid-row-start: 3;
          grid-column-start: 1;
          grid-row-end: 3;
          grid-column-end: 3;
        }
      }
    }
  }

  .gridThree {
    &.cards {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 12px;
      height: 100%;

      @media screen and (max-width: $breakpoint-ol) {
        grid-template-columns: unset !important;
      }

      @media screen and (max-width: $breakpoint-lp) {
        grid-template-columns: repeat(2, 1fr);
      }

      .singleCard:last-child {
        @media screen and (max-width: $breakpoint-ol) {
          grid-row-start: 2;
          grid-column-start: 1;
          grid-row-end: 2;
          grid-column-end: 3;
        }
      }
    }
  }

  .gridOne {
    &.cards {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      height: 100%;
    }
  }

  .gridOneActive {
    &.cards {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      height: 100%;

      .singleCard:not(:first-child) {
        display: none !important;
      }
    }

    .pdad_dialer_omc_ml {
      display: none;
    }
  }

  .dialerFlex {
    &.cards {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
      height: 100%;
    }
    .singleCard {
      flex: calc(50% - 12px);
    }
  }

  .cardWrapper {
    display: flex;
    align-items: center;
    gap: 10px;

    overflow-x: auto;

    padding-bottom: 6px;
    margin-bottom: 10px;
    width: calc(100vw - 370px);
    @media screen and (min-width: $breakpoint-xxl) {
      width: calc(100vw - 460px);
    }

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }
    &::-webkit-scrollbar-track {
      background: rgba(60, 126, 243, 0.1);
      border-radius: 4px;
    }
    &::-webkit-scrollbar {
      scrollbar-width: thin;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #949db2;
      box-sizing: border-box;
    }
    &::-webkit-scrollbar-track {
      background: #f4f5f7;
      box-shadow: inset 1px 0px 0px rgba(148, 157, 178, 0.12);
      -webkit-box-shadow: inset 1px 0px 0px rgba(148, 157, 178, 0.12);
    }

    .contactCard {
      height: 64px;
      width: 150px;
      background: $white;
      // padding: 22px 24px;
      border-radius: 8px;
      cursor: pointer;
      display: grid;
      place-content: center;

      &:not(:last-of-type) {
        margin-right: 10px;
      }

      &.active {
        border: 1.5px solid $secondary-main;
        box-shadow: $box-shadow;
        box-sizing: border-box;
      }
    }

    .contactCardUser {
      height: 64px;
      background: $white;
      padding: 12px;
      border-radius: 8px;
      cursor: pointer;

      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 40px;

      &:not(:last-of-type) {
        margin-right: 10px;
      }

      &.active {
        border: 1.5px solid $secondary-main;
        box-shadow: $box-shadow;
        box-sizing: border-box;
      }

      .avatar {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        background: $primary-light;
        color: $secondary-main;
        flex-shrink: 0;
      }
    }
  }

  .scriptWrapper {
    background-color: $white;
    width: 100%;
    height: 100%;
    display: grid;
    place-content: center;
    .scriptContent {
      width: 556px;
      height: calc(100vh - 430px);

      overflow-y: auto;

      padding: 40px;
      margin-right: 5px;

      border: $border;
      /* Elevation/10 */

      box-shadow: $box-shadow;

      border-radius: 0px 20px 0px 0px;

      &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
      }
    }
  }
}
.loaderWrp {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  flex-direction: column;
  flex-grow: 1;
}
