@import "../../assets/scss/base/variables";
.wrapper {
  position: relative;
  display: grid;
  grid-template-rows: auto calc(100vh - 191px) auto;
  // height: 96vh;

  @media screen and (min-width: ($breakpoint-xxl + 1)) and (max-width: ($breakpoint-3xl - 1)) {
    grid-template-rows: auto calc(100vh - 200px) auto;
  }
  @media screen and (min-width: ($breakpoint-3xl)) {
    grid-template-rows: auto calc(100vh - 230px) auto;
  }
  .connectingWrp {
    height: calc(100vh - 226px);
    display: flex;
  }
  .emptyWrp {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
  }
}
