@import "../../../../assets/scss/base/variables";

.sendSmsWrapper {
  height: 100%;
  display: grid;
  grid-template-rows: max-content 1fr max-content;
  .sendSmsModalBody {
    // margin-top: 16px;
    padding-top: 10px;
    padding-inline: 24px;
    border-top: 1px solid $other-divider;

    /* Message */
    .message {
      .messageTitle {
        color: $text-primary;
        display: block;
        margin-bottom: 4px;
      }
    }

    .ssmmTextarea {
      border: $border;
      border-radius: $border-radius-4;
      width: 100%;
      height: 120px;
      padding: 12px;
      resize: none;

      &:focus-visible {
        outline: none;
      }
    }

    /* Attachment */
    .attachment {
      display: flex;
      gap: 12px;
      flex-direction: column;

      /* Input */
      .personalized {
        flex: 1;
        .personalizedInput {
          height: 48px;

          color: $text-disabled;
          padding: 12px;

          display: flex;
          align-items: center;
          justify-content: space-between;

          border: $border;
          border-radius: $border-radius-4;

          cursor: pointer;

          svg {
            path {
              fill: $action-active;
            }
          }
        }
      }

      /* Attachment Left */
      .attachFile {
        color: $secondary-main;
        background-color: $secondary-states-outlined;
        max-width: 160px;
        max-height: 36px;
        padding: 6px 12px;

        margin-bottom: 12px;
      }

      .left {
        span {
          color: $secondary-main;
        }

        .attachText {
          display: block;
          color: $action-active;
        }
      }

      /* Attachment right */
      .right {
        display: flex;
        align-items: center;
        gap: 12px;

        .bookmark {
          display: grid;
          place-content: center;
          width: 48px;
          height: 48px;
          border: $border;
          border-radius: $border-radius-4;
          cursor: pointer;
        }
      }
    }

    .bookmark {
      display: grid;
      place-content: center;
      width: 38px;
      height: 38px;
      border: $border;
      border-radius: $border-radius-4;
      cursor: pointer;
    }
  }
}
// Custom PopUp - not working inside wrapper
.popDlvnWrapper {
  padding: 10px;
}
.popDlvnItem {
  padding: 10px;
  cursor: pointer;
  border-radius: $border-radius-4;
}

.popDlvnItem:hover {
  background-color: $alternate-light;
}
