@import "../../../assets/scss/base/variables";

.pdadDialerOmcMiddleImWrapper {
  // flex: 1;

  width: calc(100vw - 688px);

  @media screen and (min-width: ($breakpoint-xxl + 1)) {
    width: calc(100vw - 811px);
  }

  @media screen and (min-width: ($breakpoint-3xl)) {
    width: calc(100vw - 825px);
  }

  @media screen and (min-width: (2500px)) {
    // width: calc(100vw - 825px);
    width: unset;
    flex: 1;
  }

  transition: all linear 0.3s;

  // @media screen and (max-width: $breakpoint-lgx) {
  //   position: absolute;
  //   width: 100%;
  //   // height: 100%;
  //   transform: translateX(100%);
  //   opacity: 0;

  //   opacity: 1 !important;
  //   transform: translate(0) !important;
  //   background: $white;
  // }

  .pdadDialerOmcMiddleImTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 28px 24px 20px 24px;

    .tabHidden {
      @media screen and (max-width: $breakpoint-lgx) {
        display: none;
      }
    }

    .tabHiddenSelect {
      background: $secondary-main;
      color: $white;

      svg {
        fill: $white;

        path {
          fill: $white;
        }
      }
    }
  }

  .pdadDialerOmcMiddleImBack {
    display: none;

    @media screen and (max-width: $breakpoint-lgx) {
      display: block !important;
    }
  }

  .pdadDialerOmcMiddleImTabUl {
    // margin: 0;
    border-bottom: 1px solid $outlined-border;
    // display: flex;
    // align-items: center;
    // padding: 0 8px;
    // flex-wrap: wrap;
    // width: calc(100vw - 688px);
    // @media screen and (min-width: ($breakpoint-xxl + 1)) {
    //   width: calc(100vw - 820px);
    // }

    // @media screen and (min-width: 2560px) {
    //   width: calc(100vw - 1040px);
    // }
  }

  .pdadDialerOmcActiveTab {
    border-bottom: 2px solid $secondary-main !important;
    color: $secondary-main !important;
  }

  .pdadDialerOmcMiddleImTabLi {
    padding: 12px 8px;
    margin: 0 12px;
    cursor: pointer;
  }

  .pdadDialerOmcMiddleImNoCon {
    // text-align: center;
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;
    height: calc(100vh - 357px);
    overflow-y: auto;
    // height: calc(100% - 153px);

    &::-webkit-scrollbar {
      width: 3px;
    }

    p {
      margin: 2px 0 0 0;
    }
  }

  /* Call script start */
  .pdadDialerOmcMcallScript {
    .pdadDialerOmcMsBtn {
      display: inline-flex;
      align-items: center;
      grid-gap: 8px;
      padding: 10px;
      margin: 30px 12px 20px 12px;
      cursor: pointer;

      @media screen and (max-width: $breakpoint-lgx) {
        margin: 20px 12px;
      }
    }

    .pdadDialerOmcMcallScontent {
      display: flex;
      align-items: center;
      justify-content: center;
      // height: calc(100vh - 389px);
      height: calc(100vh - 325px);
      /* padding-top: 187px; */
    }

    .pdadDialerOmcMcallSinner {
      max-width: 556px;
      border-radius: 0px 20px 0px 0px;
      padding: 40px;
      /* height: 100%; */
      width: 100%;
      margin: 0 auto;
      border: 1px solid $outlined-border;
      box-shadow: 0px 6px 6px -3px rgba(148, 157, 178, 0.12), 0px 10px 14px 1px rgba(148, 157, 178, 0.08),
        0px 4px 18px 3px rgba(148, 157, 178, 0.06);

      @media screen and (max-width: $breakpoint-xxl) {
        // margin: 500px 24px 0 24px;
        box-sizing: border-box;
        // height: calc(100% - -500px);
      }

      p + p {
        margin: 30px 0 0 0;
      }
    }

    .scriptHeader {
      @media screen and (max-width: $breakpoint-lgx) {
        border-bottom: $border;
        margin-bottom: 20px;
      }
    }
  }

  /* Call script end */

  .conversationWrapper {
    & > div:not(:last-child) {
      margin-bottom: 30px;
    }

    padding: 32px 24px;

    @media screen and (min-width: $breakpoint-lgx) and (max-width: $breakpoint-xxl) {
      padding: 32px 16px;
    }

    // width: 100%;
    // max-width: 650px;

    .avatarWrap {
      .avatar {
        color: $white;
        background: $text-secondary;
        font-size: 12px;

        height: 32px;
        width: 32px;
        border-radius: 50%;

        display: grid;
        place-content: center;
      }
    }

    .smsReceived {
      display: flex;
      align-items: flex-end;
      gap: 10px;
      // flex-direction: row-reverse;

      .content {
        .contentHeader {
          margin-bottom: 4px;
        }

        .contentBody {
          .textWrap {
            width: fit-content;
            max-width: 604px;

            background-color: $other-divider;

            padding: 8px 12px;

            &:last-child {
              border-radius: 0 8px 8px 8px;
            }

            &:first-child {
              border-radius: 8px 8px 8px 0;
            }

            &:not(:last-child) {
              margin-bottom: 5px;
            }
          }
        }
      }
    }

    .smsSend {
      display: flex;
      align-items: flex-end;
      flex-direction: row-reverse;
      gap: 10px;

      .content {
        .contentHeader {
          margin-bottom: 4px;
        }

        .contentBody {
          .textWrap {
            width: fit-content;
            max-width: 604px;
            max-height: 300px;
            overflow: auto;

            background-color: $secondary-states-outlined-hover-background;

            padding: 8px 12px;

            &:last-child {
              border-radius: 8px 0 8px 8px;
            }

            &:first-child {
              border-radius: 8px 8px 0 8px;
            }

            &:not(:last-child) {
              margin-bottom: 5px;
            }
          }
        }
      }
    }

    .emailReceived {
      display: flex;
      align-items: flex-end;
      // flex-direction: row-reverse;
      gap: 10px;

      .content {
        .contentHeader {
          text-align: right;
          margin-bottom: 4px;
        }

        .contentBody {
          .subject {
            // height: 26px;
            display: flex;
            gap: 5px;

            width: 100%;
            max-width: 604px;

            background-color: $alternate-light;

            padding: 4px 12px;
            border-radius: 8px 8px 0 0;
          }

          .textWrap {
            width: fit-content;
            max-width: 550px;
            max-height: 300px;
            overflow: auto;

            background-color: $other-divider;

            padding: 8px 12px;
            border-radius: 0 0 0 8px;

            @media screen and (min-width: $breakpoint-lgx) and (max-width: $breakpoint-xxl) {
              max-width: 400px;
            }

            &::-webkit-scrollbar {
              width: 4px;
            }

            &:not(:last-child) {
              margin-bottom: 5px;
            }
          }
        }
      }
    }

    .emailSend {
      display: flex;
      align-items: flex-end;
      flex-direction: row-reverse;
      gap: 10px;

      .content {
        .contentHeader {
          margin-bottom: 4px;
        }

        .contentBody {
          .subject {
            // height: 26px;
            display: flex;
            gap: 5px;

            width: 100%;
            max-width: 604px;

            background-color: $alternate-light;

            padding: 4px 12px;
            border-radius: 8px 8px 0 0;
          }

          .textWrap {
            width: fit-content;
            max-width: 604px;
            min-width: 210px;
            overflow-x: auto;

            background-color: $other-divider;

            padding: 8px 12px;
            border-radius: 0 0 8px 0;

            &:not(:last-child) {
              margin-bottom: 5px;
            }
          }
        }
      }
    }

    .voiceSend {
      display: flex;
      align-items: flex-end;
      flex-direction: row-reverse;
      gap: 10px;

      .content {
        .contentHeader {
          margin-bottom: 4px;
        }
      }
    }

    .voiceReceived {
      display: flex;
      align-items: flex-end;
      // flex-direction: row-reverse;
      gap: 10px;

      .content {
        .contentHeader {
          margin-bottom: 4px;
        }
      }
    }

    .callReceived {
      display: flex;
      align-items: flex-end;
      gap: 10px;

      // flex-direction: row-reverse;
      .content {
        .contentHeader {
          margin-bottom: 4px;
        }

        .contentBody {
          .textWrap {
            width: fit-content;
            max-width: 604px;

            background-color: $other-divider;

            padding: 8px 12px;

            &:last-child {
              border-radius: 0 8px 8px 8px;
            }

            &:first-child {
              border-radius: 8px 8px 8px 0;
            }

            &:not(:last-child) {
              margin-bottom: 5px;
            }
          }
        }
      }
    }

    .directMailReceived {
      display: flex;
      align-items: flex-end;
      // flex-direction: row-reverse;
      gap: 10px;

      .content {
        .contentHeader {
          text-align: right;
          margin-bottom: 4px;
        }

        .contentBody {
          .subject {
            // height: 26px;
            display: flex;
            gap: 5px;

            width: 100%;
            max-width: 604px;

            background-color: $alternate-light;

            padding: 4px 12px;
            border-radius: 8px 8px 0 0;
          }

          .textWrap {
            width: fit-content;
            max-width: 550px;
            max-height: 300px;
            overflow: auto;

            background-color: $other-divider;

            padding: 8px 12px;
            border-radius: 0 0 8px 0;

            @media screen and (min-width: $breakpoint-lgx) and (max-width: $breakpoint-xxl) {
              max-width: 400px;
            }

            &::-webkit-scrollbar {
              width: 4px;
            }

            &:not(:last-child) {
              margin-bottom: 5px;
            }
          }
        }

        .attachment {
          display: flex;
          flex-direction: column;
          gap: 5px;

          .attachmentItem {
            color: $secondary-main;
          }
        }
      }
    }

    .directMailSend {
      display: flex;
      align-items: flex-end;
      flex-direction: row-reverse;
      gap: 10px;

      .content {
        .contentHeader {
          margin-bottom: 4px;
        }

        .contentBody {
          display: flex;
          flex-direction: column;
          justify-content: stretch;

          .subject {
            // height: 26px;
            display: flex;
            gap: 5px;

            width: 100%;
            max-width: 604px;

            background-color: $alternate-light;

            padding: 4px 12px;
            border-radius: 8px 8px 0 0;
          }

          .textWrap {
            // width: fit-content;
            max-width: 604px;
            min-width: 210px;
            overflow-x: auto;

            background-color: $other-divider;

            padding: 8px 12px;
            border-radius: 0 0 0 8px;

            &:not(:last-child) {
              margin-bottom: 5px;
            }
          }
        }

        .attachment {
          display: flex;
          flex-direction: column;
          gap: 5px;

          .attachmentItem {
            color: $secondary-main;
          }
        }
      }
    }
  }
}
