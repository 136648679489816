ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* global scroll bar for pdad */
.pdad-scroll-bar {
  height: 100%;
  padding-bottom: 4px;
  overflow-y: auto;
}
.pdad-scroll-bar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.pdad-scroll-bar::-webkit-scrollbar-track {
  background: rgba(60, 126, 243, 0.1);
  border-radius: 4px;
}
.pdad-scroll-bar::-webkit-scrollbar {
  scrollbar-width: thin;
}
.pdad-scroll-bar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #949db2;
  box-sizing: border-box;
}
.pdad-scroll-bar::-webkit-scrollbar-track {
  background: #f4f5f7;
  box-shadow: inset 1px 0px 0px rgba(148, 157, 178, 0.12);
  -webkit-box-shadow: inset 1px 0px 0px rgba(148, 157, 178, 0.12);
}
