.deleteOverlay {
  z-index: 1300 !important;
  background-color: rgba(0, 0, 0, 0.5) !important;

  :global(.react-confirm-alert) {
    background-color: #fff;
    padding: 30px;
    border-radius: 12px;
    width: 500px;
    @media (max-width: 900px) {
      width: 300px;
    }
  }
}
