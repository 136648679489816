@import "../../../assets/scss/base/variables";

.contactInfoWrapper {
  border-bottom: 1px solid $outlined-border;
  padding: 24px;

  .aboutInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 10px;
    margin: 0 0 18px 0;
  }

  .aboutTitle {
    display: flex;
    align-items: center;
    grid-gap: 10px;
  }
  // wrapper end
}
