@import "../../../assets/scss/base/variables";

.wrapper {
  width: 360px;
  flex-shrink: 0;
  border-right: 1px solid $outlined-border;
  transition: all linear 0.3s;

  &::-webkit-scrollbar {
    width: 3px !important;
  }

  @media screen and (max-width: $breakpoint-xxl) {
    width: 300px;
  }

  @media screen and (max-width: $breakpoint-lgx) {
    width: 100%;
  }

  .sectionWrapper {
    @media screen and (max-width: $breakpoint-lgx) {
      padding-inline: 80px;
    }
  }
}
