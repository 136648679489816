@import "../../../assets/scss/base/variables";

.keypadWrapper {
  .keypadTopSection {
    position: relative;

    .keypadTopInput {
      width: 100%;
      font-size: 14px;
      box-sizing: border-box !important;
      line-height: 30px;
      color: $text-secondary;
      border-radius: $border-radius-10;
      border: $border !important;
      // padding: 0 8px 0 11px;
      padding: 5px 10px;
      -moz-appearance: textfield;

      &:focus {
        outline: none;
        box-shadow: none !important;
        border-bottom-color: $outlined-border !important;
      }

      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
      }
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    }
    .keypadTopInput2 {
      border-radius: 10px;
      border-right: none !important;
      border-top-right-radius: unset !important;
      border-bottom-right-radius: unset !important;
    }

    .keypadTopIcon {
      cursor: pointer;
      svg {
        background: $kp-top-icon;
        border-radius: 50%;
        height: 18px;
        width: 18px;
        position: absolute;
        top: 14px;
        right: 16px;
      }
    }
  }

  .keypadNumbersWrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 16px;
    padding: 24px 0 0 0;

    .keypadSingleNumCenter {
      justify-content: center !important;
    }
  }

  .keypadSingleNumber {
    background: $kp-single;
    border-radius: 10px;
    color: $other-dark !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 15px;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    line-height: 34px;
    height: 40px;
    transition: all linear 0.3s;
    span {
      font-weight: 300;
      font-size: 10px;
      line-height: 26px;
      letter-spacing: 0.01em;
    }
    &:hover {
      background: $other-dark;
      color: $white !important;
    }
  }

  //   wrapper end
}
