@import "../../../../assets/scss/base/variables";

.sendVoiceWrapper {
  height: 100%;
  // display: grid;
  // grid-template-rows: max-content 1fr max-content;

  .modalBody {
    padding-top: 16px;
    padding-inline: 24px;
    border-top: 1px solid $other-divider;

    .svmwAction {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 6px;
      margin-bottom: 18px;

      @media screen and (min-width: 1440px) {
        gap: 4px;
      }
    }

    .svmwActionButton {
      color: $secondary-main;
      height: 36px !important;
      background-color: $secondary-states-outlined !important;

      @media screen and (min-width: 1440px) {
        width: 104px;
      }

      @media screen and (min-width: 2200px) {
        width: 100%;
      }

      svg {
        path {
          fill: $secondary-main;
        }
      }

      &.stop {
        color: $text-disabled;
        background-color: $action-selection !important;

        svg {
          path {
            fill: $text-disabled;
          }
        }
      }
    }

    .bookmark {
      height: 36px !important;
      min-width: 36px !important;
      background-color: transparent !important;
      border: $border;
      border-radius: $border-radius-4;
    }

    .svmwHelperText {
      padding-left: 20px;

      li {
        color: $text-secondary;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 10px;
        list-style-type: disc;
      }
    }
  }
}
