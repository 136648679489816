@import "../../../assets/scss/base/variables";

.tagWrapper {
  border-bottom: 1px solid $outlined-border;
  padding: 24px;

  .tagsWrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    grid-gap: 12px;
  }
  .tagItem {
    padding: 3px 10px;
    background: $alternate-light;
    border-radius: 30px;
    color: $secondary-main;
    cursor: pointer;
  }
}
