@import "../../../assets/scss/base/variables";

.wrapper {
  width: 420px;
  flex-shrink: 0;
  border-left: 1px solid $outlined-border;
  transition: all linear 0.3s;

  .backBtn {
    display: none;
  }

  @media screen and (max-width: 1140px) {
    width: 100% !important;

    .tabHidden {
      display: none;
    }

    .backBtn {
      display: inline-flex;
      align-items: center;
      grid-gap: 8px;
      // padding: 10px;
      margin: 20px 12px;
      cursor: pointer;
      color: $action-active !important;

      svg {
        path {
          fill: $action-active;
        }
      }

      @media screen and (max-width: $breakpoint-lgx) {
        margin: 20px 12px;
      }
    }
  }
  @media screen and (max-width: 1440px) {
    width: 360px;
  }
  @media screen and (min-width: 2200px) {
    width: 500px;
  }

  .pdadDialerOmcMiddleIrInScroll {
    /* height: calc(100vh - 321px); */
    // height: calc(100vh - 245px);
    height: calc(100vh - 200px);
  }

  .pdadDialerOmirSendWr {
    padding: 20px 0 0 0;

    .pdadDialerOmirSendItem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 6px 0;
      margin: 6px 0;
      cursor: pointer;
    }

    .pdadDialerOmirSiL {
      display: flex;
      justify-content: space-between;
      align-items: center;
      grid-gap: 12px;
    }

    .pdadDialerOmirSendItemIcon {
      height: 28px;
      width: 28px;
      border-radius: 4px;
      background: $other-divider;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }
  }

  .pdadDialerOmirSendItemActive {
    .pdadDialerOmirSiL {
      color: $secondary-main;
    }
    .pdadDialerOmirSiRightArrow path {
      fill: $secondary-main;
    }
  }

  .pdadDialerOmcMiddleIrInMobile {
    display: none;

    @media screen and (max-width: 1140px) {
      display: block !important;
    }
  }

  .pdadDialerOmcMiddleIrimTop {
    display: flex;
    justify-content: space-between;
    padding: 20px 20px 40px 20px;
  }

  .pdadDialerOmcMiddleIrProfile {
    display: flex;
    align-items: center;
    grid-gap: 20px;
    border-bottom: 1px solid $outlined-border;
    padding: 24px;

    @media screen and (max-width: 1140px) {
      justify-content: center;
      flex-direction: column;
      text-align: center;
      grid-gap: 12px;
    }
  }

  .pdadDialerOmcMiddleIlProStatus {
    margin: 2px 0 0 0;

    span {
      color: $success-main;
      margin-left: 8px;
    }
  }

  .pdadDialerOmcMiddleIrimBtnWr {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 12px;
    padding: 24px 0 40px 0;
    .pdadDialerOmcMiddleIrimBtn {
      background: $other-divider;
      padding: 4.5px 18.5px;
      width: 160px;
      border: 1.5px solid $other-divider;
      border-radius: 4px;
    }
    .pdadDialerOmcMiddleIrimBtnActive {
      border-color: $action-active;
      background: transparent;
    }
  }
}
