@import "../../../../assets/scss/base/variables";

.wrapper {
  height: 180px;
  width: 100%;

  textarea {
    border: none;
    outline: none;
  }

  ul {
    border: 1px solid $outlined-border;

    li {
      padding: 5px 10px;

      &:not(:last-child) {
        border-bottom: 1px solid $outlined-border;
      }

      &:hover {
        background-color: #cee4e5;
      }
    }
  }

  .secondWrapper {
    font-weight: bolder;
  }
}
