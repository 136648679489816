.dots {
  width: 5px;
  aspect-ratio: 1;
  position: relative;

  &::before,
  &::after {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 50%;
    background: #006df5;
  }

  &::before {
    box-shadow: 7px 0 #006df5;
    animation: d8-1 1s infinite linear;
  }

  &::after {
    transform: rotate(0deg) translateX(-7px);
    animation: d8-2 1s infinite linear;
  }

  @keyframes d8-1 {
    100% {
      transform: translateX(-7px);
    }
  }
  @keyframes d8-2 {
    100% {
      transform: rotate(180deg) translateX(-7px);
    }
  }
}
