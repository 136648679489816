.meis_animated_icon {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  float: left;
  cursor: pointer;
}
.meis_animated_icon .meis_ai_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.meis_animated_icon .meis_ai_wave {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border-radius: 50%;
}
.meis_animated_icon.meis_ai_clicked .meis_ai_wave {
  animation: meis_animated_icon_animation 0.5s ease-in-out;
  /* background-color: #ffffff1a; */
  background-color: #006df5;
  opacity: 0;
}
@keyframes meis_animated_icon_animation {
  from {
    transform: scale(0.3);
    opacity: 1;
  }
  to {
    transform: scale(1);
    opacity: 0;
  }
  /* 0%{
        transform: scale(0);
        opacity: 1;
    }
    100%{
        transform: scale(1);
        opacity: 0;
    } */
}
